<template>
    <div>
        <nfBreadcrumb :breadList="breadList"></nfBreadcrumb>
        <div class='instrumentContent'>
            <div class='clearfix top'>
                <div class='fl devicePic'>
                    <el-image
                            style="width: 270px; height: 169px"
                            :src="list.devicePic || 'http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan/trademarket/2019-12-05/9d28408b-c75f-49e3-a8e9-0fc97dbf75a2.png'"
                            fit="cover"></el-image>
                </div>
                <div class='fl contentFl'>
                    <div class='deviceName'>
                        <span class='first ellipsis'>{{list.deviceName?list.deviceName:'暂无数据'}}</span>
                        <span>{{list.deviceProductType?list.deviceProductType:'暂无数据'}}</span>
                    </div>
                    <div class='commonFont clearfix'>
                        <img class='fl' :src="require('@/assets/images/dataShare/sjhm@2x.png')" alt="">
                        <span @click="goToQrCode" class='fl border' v-if='list.devicePhone'>{{list.devicePhone.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")}}</span>
                        <img class='fl' :src="require('@/assets/images/dataShare/pl@2x.png')" alt="">
                        <span @click="goToQrCode" class='fl'>在线联系</span>
                    </div>
                    <div class='address clearfix'>
                        <img class='fl' :src="require('@/assets/images/dataShare/dd@2x.png')" alt="">
                        <span class='fl'>{{list.deviceApplyProvince}}{{list.deviceApplyCity}}{{list.deviceApplyArea}}{{list.deviceApplyAddress}}</span>
                    </div>
                    <div>
                        <button @click="goToQrCode">我要预约</button>
                    </div>
                </div>
            </div>
            <div class='bottom'>
                <div class='titleTab'><span>基本信息</span></div>
                <div class='content'>
                    <ul class='clearfix'>
                        <li class='fl name'>仪器产地</li>
                        <li class='fl des'>{{list.deviceProductProvince}}{{list.deviceProductCity}}{{list.deviceProductArea}}{{list.deviceAddress}}</li>
                    </ul>
                    <ul class='clearfix'>
                        <li class='fl name'>产品类型</li>
                        <li class='fl des'>{{list.deviceProductType?list.deviceProductType:"暂无数据"}}</li>
                    </ul>
                    <ul class='clearfix'>
                        <li class='fl name'> 规格型号</li>
                        <li class='fl des'>{{list.deviceProductModel?list.deviceProductModel:"暂无数据"}}</li>
                    </ul>
                    <ul class='clearfix'>
                        <li class='fl name'> 制造商</li>
                        <li class='fl des'>{{list.deviceManufacturer?list.deviceManufacturer:'暂无数据'}}</li>
                    </ul>
                    <ul class='flex last'>
                        <li class='name'> 产品说明</li>
                        <li class='des flex_length' v-html='list.deviceProductIntroduction?list.deviceProductIntroduction:"暂无数据"'> </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import Vue from 'vue';
import { Image } from 'element-ui';
Vue.use(Image);
export default {
    components: {
        nfBreadcrumb
    },
    data() {
        return {
            list: [],
            breadList: [{
                name: '资源共享'
            }, {
                name: '仪器设备共享',
                path: '/instrumentShare'
            }, {
                name: '详情'
            }]
        };
    },
    mounted() {
        if (this.$route.query && this.$route.query.id) {
            this.getEquipment(this.$route.query.id);
        }
    },
    methods: {
        async getEquipment(id) {
            const api = this.$fetchApi.equipmentSelectById.api;
            const data = await this.$fetchData.fetchPost({id}, api, "json");
            if (data.code === '200' && data.result) {
                this.list = data.result;
            }
        },
        goToQrCode() {
            this.$router.push({
                path: '/downLoad'
            });
        }
    }
};
</script>
<style lang="less" scoped>
@import url('./common.less');
    .bottom ul {
        justify-content: center;
        background: #fafafa
    }
   .instrumentContent  .last{
        display: flex;
        .name{
            vertical-align: middle;
            border-right:none;
        }
        .des{
            padding-right: 30px;
            background: #fff;
            border-left:solid 1px #e3e3e3;
            line-height: 30px;
            // div{
            //     display: -webkit-box;
            //     -webkit-box-orient: vertical;
            //     -webkit-line-clamp: 3;
            //     white-space: normal;
            // }
        }
    }
</style>